<template>
  <div>
    
    <v-dialog v-model="dialogActivator" persistent content-class="modal">
      <template v-slot:activator="{}">
        <v-btn
          v-on:click="activa()"
          v-bind="$cfe.btn.buscaTexto">
            Control de cambios ({{ Ctcc[2]>""? Ctcc[2].split("$").length:"0" }})
        </v-btn>
      </template>

      
      <div>
        <v-sheet :elevation="4">          
          <baseHeader
            :cfg="$cfe.headers.mto"
            titulo='Control de cambios'                
            @onEvent="$emit('close')">
          </baseHeader>

          <div class="contenedor">
            <div class="conflex">
              <div v-if="estado=='editar'" class="columna contenedor" style="width:250px">
                <div>Indique el motivo de la modificación</div>
                <v-radio-group v-model="ncaso"
                  @change="getValueAux">
                  <v-radio
                    v-for="(caso, index) in casos"
                    :key="index"
                    :label="caso"
                    :value="index+1"
                  
                  ></v-radio>
                </v-radio-group>
              </div>

              <div  class="columna contenedor"  style="width:450px">
                <div style="padding-top:10px"> </div>
                <div  v-for="(change, index) in changes"  :key="index">
                  <div> {{change}} </div>
                </div>                
              </div>
            </div>

            <div v-if="estado=='editar'">
              <uiText            
                v-model="comentario"
                labelSide
                label="Comentario modificación"
                @change="getValueAux">
              </uiText>
            </div>
          </div>
           
        </v-sheet>
      </div>      
    </v-dialog>
  </div>
</template>



<script>
  
  import { mixCt } from "@/mixins/mixCt";
  import baseHeader from "@/base/baseHeader.vue";
  
  export default {
    mixins: [mixCt],
    components: { baseHeader },
    props: {      
      casos: { type: Array, default: function () { return [] } },
      Ctcc: { type: Array, default: function () { return [] } }, 
      estado: { type:String, default:'' },
      dialog: { type: Boolean, default: false }      
    },

    data() {
      return {
        ncaso:0,
        seleccion:0,
        comentario: "",
        changes:[],       
      };
    },

        
    methods: {
      activa() {       
        this.dialog? this.$emit("close") : this.$emit("open");        
      },


      getValueAux(nuevo=0){
        if (nuevo==99) {        
          this.Ctcc[4].comp.valueAux=this.tccId()+";"+99+";";          
          return
        }

        if(this.ncaso==90){
          this.Ctcc[4].comp.valueAux = '';

        } else {
            this.Ctcc[4].comp.valueAux= this.tccId() + ";" + this.ncaso + ";" + this.comentario;
            if (this.Ctcc[2]>"") this.Ctcc[4].comp.valueAux+= "$";            
        }

      },


      tccId() {
        let fh = new Date();
        let tcc = `${fh.getFullYear()}${String(fh.getMonth() + 1).padStart(2,"0")}${String(fh.getDate()).padStart(2, "0")}`;        
        tcc += `${String(fh.getHours()).padStart(2, "0")}${String(fh.getMinutes()).padStart(2, "0")};`;        
        tcc += this.usu.substring(0, 10);
        return tcc;
      },


      recopila() {
        // inicializo la seleccion de los radio cuando entra si no ha introducido nada
        // utilizo ncaso=90 por ejemplo, para que no marque ningun radio
        if (this.Ctcc[4].comp.valueAux== "") {
          this.ncaso= 90;
          this.comentario= '';
        }

        // Recopilo datos 
        if (!this.Ctcc[2]) {
          this.changes='';
          return;
        }

        this.changes= this.Ctcc[2].split("$").map(item => {
          let cut = item.split(";");
          if (cut[2]>0 && cut[2]<=this.casos.length) cut[2]=this.casos[cut[2]-1];
          if (cut[2]==90) cut[2]='';
          if (cut[2]==99) cut[2]='ALTA';
         
          return cut[0].substr(6, 2) + "-"+cut[0].substr(4, 2) + "-"+cut[0].substr(0, 4) + " "+cut[0].substr(8, 2) + ":"+cut[0].substr(10, 2) + " " +
                cut[1] + " " + cut[2] + " " + cut[3]
        });

      },

    },


    computed: {

      dialogActivator: {
        get: function() {
          return this.dialog;
        },

        set: function(val) {
          return val;
        }
      }

    },


    watch: {
      // los cambios de alta los hace automaticamente cundo detecta que hay un alta
      estado(newValue) {
        if (newValue=='nuevo') {
          this.ncaso=99
          this.getValueAux(99)
        }
      },

      dialog(newValue) {      
        if (newValue) this.recopila();
      }

    }
    
  };

</script>

