<!-- Mto Presupuestos Funerarias Sucursales -->

<template>
  <div class="funesPresMD" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      persistent>

      <template v-slot:controles="{}">    

        <div>
          <div class="cabecera">
            <baseHeader
              :cfg="$cfe.headers.mto"
              :titulo="$store.state[storeName].titulo"
              @onEvent="eventHeader"> 
            </baseHeader>
          </div>

          <v-sheet :elevation="4">
            <div class="contenedor">

              <!-- <div v-html="info"></div> -->
              
              <!-- botoneras -->
              <div class="conflex" style="width: 1100px; margin-bottom: 5px">
                
                <!-- btra Mto -->       
                <baseBtraMto class="conflex" style="width:230px;"             
                  :perm="perm"
                  :modulo="btMtoCfg" 
                  :estado="estado"
                  @onEvent="execAccion">        
                </baseBtraMto>

                <!-- extra -->
                <div class="conflex" style="width: 230px; margin-left: 50px">         
                  <baseBtraExtra class="conflex"           
                    :permExtra="permExtra"
                    :modulo="btExtCfg"           
                    @onEvent="execAccion">             
                  </baseBtraExtra>        
                </div>

                <!-- plantillas -->
                <v-select
                  style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                  dense
                  @change="changePlantilla()"
                  v-model="plantillaValue"
                  v-bind="$select"
                  label="Plantillas"
                  :items="itemsPlantilla"
                  :disabled="plantillaDisabled"
                  item-value="id"
                  item-text="name">            
                </v-select>

                <!-- changes presupuesto -->
                <div style="display:flex;justify-content: flex-end;align-content: flex-end">

                  <funPresChanges
                    :Ctcc="ct.tcc"
                    :casos="[
                      'ERROR IMPORTE PRODUCTO',
                      'OMISION PRODUCTO',
                      'NUEVO PRODUCTO',
                      'CAMBIO DE PRODUCTO',
                      'MODIFICACION TASAS',
                      'NUEVA NEGOCIACION',
                      'OTRAS'
                    ]"                  
                    :estado="estado"
                    :dialog="dialogChanges"
                    @close="dialogChanges = false"
                    @open="dialogChanges = true">
                  </funPresChanges>
                  
                </div>

              </div>


              <!-- Controles del Mto -->
              <div class="conflex" style="width:1190px">

                <!-- dos filas con 2 cada una generales para el formulario -->
                <!-- presupuestos/contratador -->
                <!-- detalle/productos o datos laterales segun is-editHijo -->

                <!-- fila 1  -->
                <div class="fila1 conflex">
                  <div class="columna">
                    <div class="conflex" style="justify-content:space-between">
                      <div class="cab ">DATOS PRESUPUESTO</div>
                      <div style="color:steelblue;padding-right:20px">
                        {{ record.id }}/{{ recordDataAux.id + "        " }}
                        {{ recordDataAux.name }}
                      </div>
                    </div>

                    <v-sheet v-bind="$cfg.styles.marco" style="width:750px">
                      <div class="conflexnow" style="display:flex">
                        <v-select
                          style="flex: 1 1 10%"
                          v-bind="$select"
                          v-model="ct.ano[2]"
                          :label="ct.ano[1]"
                          :items="itemsAno"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="name">                          
                        </v-select>

                        <v-select
                          style="flex: 1 1 35%"
                          v-bind="$select"
                          v-model="ct.id_presciac[2]"
                          :label="ct.id_presciac[1]"
                          :items="itemsPrestacion"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="name">                          
                        </v-select>

                        <uiText
                          style="flex: 1 1 55%"                         
                          v-model="ct.descrip[2]"
                          :label="ct.descrip[1]"
                          :disabled="noEdit">                          
                        </uiText>
                      </div>

                      <div class="conflex">
                        <div style="flex: 1 1 65%">
                          <ctrlF                             
                            :ct="ct.municipio"
                            :edicion="!noEdit">                            
                          </ctrlF>
                        </div>

                        <!-- Selección cia (MAP,KUTX) -->
                        <v-select
                          style="flex: 0 0 35%"
                          v-bind="$select"
                          v-model="ct.cia_id[2]"
                          label="Compañía"
                          :items="itemsCias"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="name">                          
                        </v-select>          
                      </div>

                      <div class="conflex">
                        <div class="columna">
                          <v-switch
                            style="flex:1 1 15%;margin:0;padding:0"
                            true-value="1"
                            false-value="0"
                            color="green"
                            :readonly="noEdit || app == 2"
                            v-model="ct.preferente[2]">
                          </v-switch>

                          <div style="margin-top:-10px;margin-right:10px">
                            Preferente
                          </div>
                        </div>

                        <v-select
                          style="text-align:center;flex: 1 1 100px"
                          v-model="ct.rt[2]"
                          v-bind="$select"
                          label="Tipo I."
                          :items="$store.state.datos_iniciales.rTribut"
                          :disabled="noEdit || !noEdit1"
                          item-value="id"
                          item-text="name"
                          @change="changeRt">
                        </v-select>

                        <v-select
                          style="flex: 1 1 100px"
                          v-model="ct.iva_general[2]"
                          v-bind="$select"
                          label="% Gral"
                          :items="getIvas"
                          :disabled="noEdit || !noEdit1"
                          item-value="value">
                        </v-select>

                        <!-- Sumas totales presupuesto (IVA, suplidos, BASE , TOTAL) -->
                        <presSumas style="flex:1 1 60%" :ct="ct"></presSumas>

                      </div>
                    </v-sheet>
                    <!-- fin columna1 -->
                  </div>

                  <div class="columna">
                    <div class="cab">CONTRATADOR</div>

                    <v-sheet
                      v-bind="$cfg.styles.marco"
                      style="width:420px;height:153px">

                      <div class="conflex">
                        <uiText
                          style="width:60%"                        
                          v-model="ct.paxfun[2]"
                          :label="ct.paxfun[1]"
                          :disabled="noEdit">                          
                        </uiText>
                        
                        <uiDate 
                          style="width:40%"
                          v-model="ct.fhrev[2]"
                          :label="ct.fhrev[1]"          
                          type="date"
                          :disabled="noEdit">          
                        </uiDate>                       
                      </div>

                      <v-textarea
                        rows="3"
                        :no-resize="true"
                        label="Observaciones"
                        v-model="ct.obs[2]"
                        :disabled="noEdit">                        
                      </v-textarea>
                    </v-sheet>
                    
                    <!-- fin fila1 columna 2 -->
                  </div>
                </div>

                <div class="fila2 conflex">
                  <!-- fila2 columna 1-->
                  <div class="columna">
                    <div class="cab" style="width:750px">DETALLE</div>
                    <baseGridMD                      
                      style="width: 750px;padding-top:10px"
                      :store="storeName"
                      :headers="headerGrid"
                      :items-per-page="-1"
                      :expansible="storeName"
                      :ordenar=true
                      dense
                      @onEvent="execAccionMX">                    

                        <!-- Slot botones Top -->
                        <template v-slot:gridTop="{}" v-if="!noEdit">
                          <baseBtraMto class="conflex"
                            :perm="perm"
                            :modulo="$cfe.btra.GRID"
                            @onEvent="execAccionMX">
                          </baseBtraMto>
                        </template>

                        <!-- Slot panel expansible -->
                        <template v-slot:expansibleMX="{}">                        
                            <div class="columna">
                              <div style="display:flex;align-items:center">
                                <funesPresMX                              
                                  sync
                                  syncUpdate
                                  :syncAccion="syncAccionSend"                 
                                  :storeRaiz="storeName"
                                  :masterStore="storeName"                                
                                  :selectProduct="selectProduct"
                                  :rt="ct.rt[2]"
                                  :ivaGeneral="ct.iva_general[2]"                                  
                                  @updatedMX="updatedMX">
                                </funesPresMX>                                            
                              </div>
                            </div>                        
                        </template>

                        <!-- Slot columna Header 'sp' -->
                        <template v-slot:sp="{item}">
                          <div style="color:red">
                            {{item.sp=='1'?'PE':''}}
                          </div>
                        </template>
                      
                        <!-- Slot columna Header 'sup' -->
                        <template v-slot:sup="{item}">
                          <div style="color:green">
                            {{item.sup=='S'?'S':''}}
                          </div>
                        </template>

                        <!-- Slot columna Header 'name_prod_id' -->
                        <template v-slot:name_prod_id="{ item}">
                          {{ item.name_prod_id }}
                          <div
                          v-if="item.namea >''" class="light-blue--text text--darken-3">
                            {{ item.namea }}
                          </div>
                        </template>

                    </baseGridMD>                
                  </div>
                  <!-- fin fila2 columna 2 ------------------------------->

                  <div v-if="noEdit1"                   
                    class="columna"
                    style="width:430px;padding-left:8px">                    
                    
                    <div class="conflex">
                      <presDiferencia
                        style="flex:0 0 50%"
                        :ct="ct"
                        :is_edit="noEdit">                        
                      </presDiferencia>

                      <presIva
                        style="flex:0 0 50%"
                        :ct="ct">                      
                      </presIva>
                    </div>
                    
                    <presClaves :Aimp="clavesAc" :T="clavesTotal" :todo="false"></presClaves>
                    
                  </div>

                  <div v-else>
                    <productosNV :edicion="!noEdit1" @eventSelectProduct="selectProduct=$event" />       
                  </div>
                  <!-- fin fila 2 columna 2 -->
                </div>

              <!-- fin bloque controles filas/columnas -->
              </div>
            </div>
          </v-sheet>
        </div>
      </template>
    </dualTemplate>


    <!-- ventana modal IMPORTACION PRESUPUESTOS -->
    <v-dialog content-class="modal" v-model="modalPresImport" persistent>
      <component      
        :is="componenteDinamico"
        sync      
        :storeRaiz="storeName"
        :masterStore="storeName"
        :recordAux="recordAux"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        :filtroImportServicio="filtroImportServicio"
        tipoView="FC"
        @onEvent="impPrestosExtraer">
      </component>
    </v-dialog>

  </div>
</template>



<script>
  
  import { mixMD } from "@/mixins/mixMD.js";

  import dualTemplate from "@/components/dualTemplate";
  // import { mixinCalculos } from "@/mixins/mixinCalculos.js";
  import { presCalculo } from "@/mixins/presCalculo.js";
  import { calculo_claves } from "@/mixins/calculo_claves.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import ctrlF from "@/components/ctrlF";
  import presSumas from "@/components/presSumas";
  import presClaves from "@/components/presClaves";
  import presIva from "@/components/presIva";
  import funesPresMX from "@/components/funesPresMX";
  import presDiferencia from "@/components/presDiferencia";
  import productosNV from "@/components/productosNV";
  import funPresChanges from "@/components/funPresChanges";
  import presImportF from "@/components/presImportF";
  /* const compfecha = () => plugs.groute("compfecha.vue", "comp"); */    
  // const pres_total_cia = () => plugs.groute("presIva.vue", "comp");    
  /* const funes_pres_import_F = () =>
    plugs.groute("funes_pres_import_F.vue", "comp"); */

export default {
  mixins: [mixMD, presCalculo, calculo_claves],
  components: {
    baseHeader,
    baseBtraMto,
    baseGridMD,
    baseBtraExtra,
    dualTemplate,
    ctrlF,
    /* compfecha, */    
    /* funesPresMX, */
    presClaves,
    presSumas,
    presIva,
    presDiferencia,
    funesPresMX,
    productosNV,
    funPresChanges,
    presImportF
    /* funes_pres_import_F */
  },

  props: {   

    //paso datos de filtro para filtrar los presupuestos que puedo importar
    filtroImportacion: {},
    
  },


  data() {
    return {
      stIni: {
        api: "funesPresMD",
        name: "funesPresMD",  
        titulo: "Mto Presupuestos Funerarias",
        recordAccess: "local",
        relation: "fun_id",
        mView:'',
        pView:[]     
      },

      // variable apertura automática fun_pres_changes
      dialogChanges: false,

      // datos auxiliares funeraria recordAux
      dataAux:{
        id:0,
        name:'Error: Funeraria no encontrada'
      },

      // item del producto seleccionado
      selectProduct:null,

      mensajeGuardarFin:'',

      //
      //disparo_funes_pres_import: false,
  
      // plantillas
      itemsPlantilla: [
        { id: "4", name: "Servicio Incineración" },
        { id: "3", name: "Servicio Inhumacion" },
        { id: "5", name: "Servicio traslado" },
        { id: "6", name: "Servicio recepción/terminación" }
      ],
      plantillaValue: "0",
      plantillaDisabled: true,

      // -----     
      itemsAno: [],
      //fila_copiada: null,
      //pservicios: null,
      tipo_detalle: "pres",

      // array de productos de IVA al 10%
      iva10: [],

      // array de Compañías
      itemsCias: [
        { id: "83", name: "MAPFRE" },
        { id: "50165", name: "KUTXABANK" }
      ],
      
      // array de Tipos de prestación
      itemsPrestacion: [],

      // filtro del finder de importación (exp_id, ...)
      filtroImportServicio:{},

      // A tener en cuenta antes de grabar presupuestos
      noduplicar_tipos: [10, 30, 18, 38, 36, 16, 17, 37], 
      municipio_required: [10, 11, 30, 31, 18, 38, 36, 16, 17, 37],
      apd_tipos: [10, 11, 30, 31, 18, 38],
      apd_cias: [50165, 50508]
    };
  },


  methods: {
    iniDataParticular() {
      console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");      
      
      // defino botones extra de la cabecera
      this.btExtCfg= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));     
      this.btExtCfg.btnsAccion= this.btExtCfg.btnsAccion.concat([  
        { accion: "impPrestos", texto: "IMP", show: 1 },
        { accion: "copy", texto: "copy", show: 1 },
        { accion: "pega", texto: "pega", show: 1 },
        { accion: "pdf", btn: "pdf", title: "Ver Pdf", show: 1 }
      ]);

      // defino botones lineas detalle      
      this.btRowCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));
      
      // Cabecera Finder
      this.headers = [{
        name:[],
        fields:[         
          { text: "CONCEPTO", value: "name_prod_id", width: "50%", slot: true },
          { text: "PE", value: "sp", slot:true},
          { text: "SUP", value: "sup", style: "color:green", slot: true },
          {
            text: "UD",
            value: "und",
            align: "center",
            style: "text-align:right",
          },
          //{ text: "P/T", value: "grup" },

          { text: "IVA", value: "iva", style: "text-align:right" },
          { text: "PRECIO", value: "pvp", style: "text-align:right" },
          {
            text: "IMP",
            value: "imp",
            style: "font-weight:bold;text-align:right;color:#01579B",
          },
        ],
      }];

      this.headerGrid= this.headerSet();
      
      // array de tipos de prestación
      this.itemsPrestacion = this.$store.state.datos_iniciales.tipo_presup;

      // array de productos con iva al 10%
      this.iva10= this.$store.state.datos_iniciales.iva10;  

      // array de años
      this.itemsAno = this.getItemsAno();
    },


    iniDataApi() {
      
      this.apiArgs= {
        sch: ["mto", 'getSchema', {}, this.stIni.api ],
        get: ["", "get", { tipo: "pres", id: 0 }, "presupuesto"],
        set: ["", "set", { tipo: "pres", id: 0, cab: {}, lin: [] }, "presupuesto"],
        eliminar: ["", "eliminar", { tipo: "pres", id: 0 }, "presupuesto"],
        plantilla: ["", "plantilla", { rt:'', id:0 }, "presupuesto"],
        pdf: ['', 'pdf', { id:0 }, "presupuesto"],
        importar: ["", "importar", { id:0, gen:0, exp_id:0 }, "presupuesto"],
        preferente: ["", "preferente", { id:0, ano:0, fun_id:0, cia_id:0, id_presciac:0, municipio:'' }, "presupuesto"],
        apdCalculo: ["", "mysqlProc", { multiApi:[] }], 
        fusion: ["", "fusionLineas", { tipo: "pres", id: 0 }, "presupuesto"]
      }        
      
    },


    


    // Sobreescribir para que no extraiga datos
    // o para extraccion fuera de las definidas en el mixMD
    //async getDataAux () {     
      // actualizo dataAux
      //  this.recordDataux={...this.dataAux, ... this.recordAux};
    //},


    // entro al modificar / eliminar MX
    updatedMX() {      
      this.calculo();
    },


    calculo() {
      this.pres_calculo(this.records1, this.ct, "C");
      this.calculo_claves(this.records1);
    },


    //
    getItemsAno() {
      let ans = [];
      let i = 0;
      let a = new Date().getFullYear() - 1;
      while (i < 3) {
        ans.push({ id: (a + i).toString(), name: a + i });
        i++;
      }

      return ans;
    },


    //
    nuevoBefore() {
      this.$set(this.ct.iva_general, 2, this.$store.state.datos_iniciales.rTribut[this.ct.rt[2]].tipos[0].value);      
    },


    // gancho final en todas las acciones CRUD
    crudFin() {       
      this.calculo_claves(this.records1);
      
      
      // Inicializo tipo de iva      
      /* if (!this.ct.rt[2]) {       
        //this.$set(this.ct.rt, 2, "0");
        this.$set(this.ct.iva_general, 2, this.$store.state.datos_iniciales.rTribut[
          this.ct.rt[2]
        ].tipos[0].value);         
      } */
      
      // Inicializo año para altas
      // apds lo tienen disabled en template solo usan este
      // apds Nuevos solo año actual y siguiente año a partir de noviembre
      if (!this.conDato(this.ct.ano[2])) {
        this.$set(this.ct.ano, 2, String(new Date().getFullYear()));
        if (new Date().getMonth() > 10) {
          this.$set(this.ct.ano, 2, String(new Date().getFullYear() + 1));
        }
      }
      // boton importacion solo en edicion
      this.btnSet(this.btExtCfg, "impPrestos", { visible: true, disabled: this.noEdit });
      this.plantillaDisabled = this.noEdit;
      //this.btnSet("copy",{visible:true, disabled:!this.is_edit});
      this.btnSet(this.btExtCfg, "pega", { visible: true, disabled: this.noEdit});
      // apds
      // No eliminar y No edit anteriores años

      this.btnSet(this.btMtoCfg, 3, { view: true, disabled: this.app == 2 });
      this.btnSet(this.btMtoCfg, 2, { view: true, disabled: false });
      
      // Pendiente: Poner año actual - 1 mas algo
      if (Number(this.ct.ano[2]) < 2021) {
        this.btnSet(this.btMtoCfg, 3, { view: true, disabled: true });
        this.btnSet(this.btMtoCfg, 2, { view: true, disabled: true });
      }
    },


    // comprobación de datos particulares antes de Guardar
    async validateParticular() {
      this.mensajeGuardarFin='';
      
      if (this.ct.tcc[4].comp.valueAux == "" && this.estado != "nuevo") {
        this.dialogChanges = true;
        return false;
      }

      // Chequear compañía obligatoria
      if (!this.conDato(this.ct.cia_id[2])) {         
        this.$root.$alert.open("Debe introducir compañía", "error");
        return false;
      }

      // Chequear Tipo de Servicio obligatorio
      if (!this.conDato(this.ct.id_presciac[2])) {
        this.$root.$alert.open("Debe introducir un tipo de servicio", "error");
        return false;
      }
      
      // Chequear Municipio según Tipo de Servicio
      if (!this.conDato(this.ct.municipio[2]) && this.municipio_required.includes(Number(this.ct.id_presciac[2]))) {
        this.$root.$alert.open("Debe introducir un municipio", "error");
        return false;
      }

      // Chequear si existe algún producto de Flores con el IVA incorrecto
      let records1= JSON.parse(JSON.stringify(this.records1));
      let errorIva10= false;
      records1.forEach(item => {        
        if (this.rTributName == "IVA" && this.iva10.includes(Number(item.prod_id)) && Number(item.iva)> 10) {
          errorIva10= true;
        }
      });

      if (errorIva10) {
        this.$root.$alert.open("Hay uno o varios productos de Flores con el IVA incorrecto", "error");
        return false;
      }

      // Si llega aquí es que no se necesita municipio y no hago mas comprobaciones
      if (!this.conDato(this.ct.municipio[2])) return true;
      

      // llamada API preferente para multiples chequeos
      // Chequeo si presupuesto duplicado de la misma funeraria o si existe o tro preferente de otra funeria
      // PREFERENTE de un mismo tipo de presupuesto por un municipio y una compañia y año solo puede haber uno.
      // Chequear posibles duplicaciones de la misma funeraria

      let args= {
        id: this.ID,
        ano: this.ct.ano[2],
        fun_id: this.recordDataAux.id,
        cia_id: this.ct.cia_id[2],
        id_presciac: this.ct.id_presciac[2],
        municipio: this.ct.municipio[2]
      };

      let param= { apiArg:this.apiArgs.preferente, args:args };      
      let apiResult = await this.$store.dispatch("apiCall", param);

      // Pendiente: controlar error API
      // Datos recibidos en apiResult:
      // apiResult.r[0]: duplicadas // (no hay duplicadas=0, nombre de funeraria)
      // apiResult.r[1]: duplicada preferente
      // apiResult.r[2]: precio APD

      console.log("check preferente:", apiResult)
      

      // Chequeo duplicado (el servidor ya tiene los tipos que no se pueden duplicar)
      // SI (apiResult.r[0]>0 quiere decir que la funeraria tiene un presupueto del tipo que solo permite uno 
      if (apiResult.r[0] != 0) {
        this.$root.$alert.open(" La funeraria ya tiene presupuesto y para este tipo solo se permite uno", "error");
        return false;
      }

      // Chequeo preferente (solo puede haber una por funeraria y tipo de presupuesto)
      if (this.ct.preferente[2] == "1" && apiResult.r[1] != 0) {
        this.$root.$alert.open("Ya existe un preferente en: " + apiResult.r[1], "error");
        return false;
      }

      // chequear APD obligatorio para determinadas centrales de sucursales y para un determinado tipo de presupuesto
      // Chequeo si la cia obliga a apd
      if (!this.apd_cias.includes(Number(this.ct.cia_id[2]))) return true;

      //Chequeo si el tipp de presupuesto obliga a apd
      if (!this.apd_tipos.includes(Number(this.ct.id_presciac[2]))) return true;

      // Busco si existe en el detalle alguna linea de APD (record.prod_id=149)      
      let lineaApdIndex= records1.findIndex(record => Number(record.prod_id) == 149);      
      if (lineaApdIndex>= 0) {
        if (apiResult.r[2]>0 && records1[lineaApdIndex].imp!= apiResult.r[2]) {
          
          // modifico campos de la linea de atención personal
          records1[lineaApdIndex].imp= apiResult.r[2];
          records1[lineaApdIndex].pvp= apiResult.r[2];

          // actualizo records GRID y marco como modificado
          this.$store.commit(this.storeName + '/recordSet1', records1);
          this.$store.commit(this.storeName + '/data2State', { prop:'change1', value:true });

          // recalculo
          this.detalleModificado();         
          this.mensajeGuardarFin= 'Modificado automáticamente el precio del APD';
        }
        
        return true;
      }

      
      // record de 'Atención Personal'      
      let CDRecord= {
        clave: "CD",
        grup: "P",
        imp: apiResult.r[2],
        iva: this.ct.iva_general[2],
        namea: "",
        orden: 99,
        prod_id: "149",
        pvp: apiResult.r[2],
        sp: 1,
        sup: "N",
        und: 1
      };

      records1.push(CDRecord);

      /* if (index>= 0) {        
        // comprobar si el precio del APD está actualizado, si no lo está se actualiza y se muestra mensaje de que se ha actualizado con precio anterior y nuevo
        if (records1[index].pvp!= apiResult.r[2] || records1[index].iva!= this.ct.iva_general[2]) {          
          this.$root.$alert.open("El precio del APD se ha actualizado de " + records1[index].pvp + " a " + apiResult.r[2], 'info');
          records1[index]= CDRecord;        
        }

      }else {
        // se añade APD automático
        records1.push(CDRecord);
        this.$root.$alert.open("APD añadido automáticamente para esta compañía y tipo de presupuesto " + apiResult.r[1], "error");
      } */
    
      
      // actualizamos records GRID y marcamos como modificado
      this.$store.commit(this.storeName + '/recordReadSet1', records1);
      this.$store.commit(this.storeName + '/data2State', { prop:'change1', value:true });

      // recalculo
      this.detalleModificado();

      //
      this.mensajeGuardarFin= 'APD añadido automáticamente para esta compañía y tipo de presupuesto';
      return true;
    },


    // gancho después de guardar
    guardarFin() {      
      if (!this.mensajeGuardarFin) {        
        this.$root.$alert.open("Registro guardado correctamente!", "success", 1800); 
        
        // gancho final CRUD
        this.crudFin();
        return true;
      }

      this.$root.$alert.open(this.mensajeGuardarFin, 'info', 5000);    
      this.mensajeGuardarFin= '';
      
      // gancho final CRUD
      this.crudFin();
    },


    // ACCIONES QUE SE REALIZAN CUANDO HAY UNA MODIFICACION EN EL DETALLE
    detalleModificado() {
      this.calculo();
    },


    // entra al pulsar en el botón de importar presupuestos
    impPrestos() {
      if (this.noEdit) return;
      //this.componenteDinamico = "funesPresImportF";
      this.componenteDinamico = "presImportF";
      this.filtroImportServicio.exp_id= 0;
    },

    async impPrestosExtraer(evt) {
      console.log('EVENTO impPrestosExtraer: ', evt);

      // eliminamos componente Dinámico
      // al eliminarlo se cierra automáticamente la ventana modal
      this.componenteDinamico= null;

      // si la acción es cerrar no continuo
      if (evt.accion == "cerrar" || evt.accion== 6) return;
      
      // si llega aquí llegamos con una accion=extraer
      let lins = [];
      lins.push(evt.item.id);

      let param= { apiArg:this.apiArgs.fusion, args:{ id:lins }};      
      let apiResult = await this.$store.dispatch("apiCall", param);
      console.log('apiResult importLINS: ', apiResult);

      //
      if (apiResult.sql.error) {
        this.$root.$alert.open('Error al importar Presupuesto', 'error');
        return;
      }
      
      lins = apiResult.r[0];      
      if (lins.length) this.lineas2records(lins);
      return;
    },

    lineas2records(lins) {      
      if (Array.isArray(lins) && lins.length) {
        this.$store.commit(this.storeName + "/recordSet1", lins);
        this.detalleModificado();
      }
    },

    //
    copy() {      
      const records = JSON.parse(JSON.stringify(this.records1));
      if (!records.length) {
        this.$root.$alert.open("No hay datos para copiar", "error");
        return;
      }

      this.$store.commit("data2State", { modulo: "datos_iniciales", prop: "recordsCopy", value: records });
      this.$root.$alert.open("Datos copiados", "info");
    },

    //
    pega() {      
      const records = JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.recordsCopy));
      if (!records.length) {
        this.$root.$alert.open("No hay datos para pegar", "error");
        return;
      }

      if (records.length && !this.noEdit) {        
        this.$store.commit(this.storeName + '/recordSet1', records);
        this.detalleModificado();
        this.$root.$alert.open("Datos pegados", "info");
      }
    },

    // ver pdf
    async pdf() {
      if (this.ID == 0) return;
      let param= { apiArg:this.apiArgs.pdf, args:{ id: this.ID }};
      let apiResult = await this.$store.dispatch("apiCall", param);
      console.log("pdf Result:", apiResult)

      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }

      // abro documento
      window.open(this.$store.state.G.url_tmpNV + apiResult.r[0], "_blank");
    },


    // entro al cambiar el Regimen Tributario
    changeRt() {
      let records = JSON.parse(JSON.stringify(this.records1));
      this.changeIva(records);
    },


    // cambio el iva de cada linea según el valor seleccionado en 'ct.rt'
    // compruebo el IVA de los tipos de producto Flores
    changeIva(records) {      
      let ivaStr = this.$store.state.datos_iniciales.rTribut[this.ct.rt[2]].tipos[0].value;
      this.ct.iva_general[2] = ivaStr;      
      
      records.forEach(item => {
        if (item.sup == "N") item.iva = ivaStr;
        if (
          this.rTributName == "IVA" &&
          this.iva10.includes(Number(item.prod_id))
        ) {
          item.iva = "10.0";
        }
      });

      this.lineas2records(records);      
    },


    // entro al seleccionar una plantilla para importar
    changePlantilla() {
      if (this.plantillaValue == "0") return;
      this.$root.$alert
        .open("¿Importar  plantilla?", "confirm")
        .then(r => { if (r) this.changePlantillaFin(); });
    },

    async changePlantillaFin() {
      let param= { apiArg:this.apiArgs.plantilla, args:{ rt:this.ct.rt[2], id:this.plantillaValue }};
      console.log("changePlantillaRespuesta *************************:", this.ct.rt[2], this.plantillaValue);      
     
      this.plantillaValue = "0";
      let apiResult = await this.$store.dispatch("apiCall", param);
      console.log("plantilla Result:", apiResult)
      
      //
      if (apiResult.sql.error) {
        this.$root.$alert.open('Error al importar Plantilla', 'error');
        return;
      }

      this.changeIva(apiResult.r[0]);
    },

    conDato(i) {
      if (i == "0" || i == "" || typeof i === "undefined" || i == null)
        return false;
      return true;
    }
  },


  computed: {
  
    //
    getIvas() {
      if (!this.$store.state.datos_iniciales) return [];
      if (this.ct.rt[2]) {
        return this.$store.state.datos_iniciales.rTribut[
          this.ct.rt[2]
        ].tipos;
      }

      return [];
    },

    //
    rTributName() {
      if (this.ct.rt[2] != "") {
        return this.$store.state.datos_iniciales.rTribut[
          Number(this.ct.rt[2])
        ].name;
      }
      return "";
    },

    relationID() {
      // Campo relacionado   
      return this.recordAux.id;
    }, 
    
    
    modalPresImport() {
      return this.componenteDinamico!= null? true : false;
    }

  },

};
</script>
