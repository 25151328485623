<!-- Finder particular de Funerarias Sucursales -->

<template>
  <div class="funesPresF" v-if="loaded">
    <div class="conflex">
      <v-sheet :elevation="4" style="width:100%">
     
        <!-- Contenido -->
        <div class="contenedor">
         
          <!-- Filtro de Busqueda -->
          <div class="conflex">
          
            <v-select 
              style="flex: 0 0 10%"
              v-bind="$select"
              v-model="ct.ano[2]"
              :items="itemsAno"
              :label="ct.ano[1]"
              item-value="ano"
              item-text="label"
              @change="eventFiltro()">            
            </v-select>
            
            <v-select 
              style="flex: 0 0 35%;padding-left:5px;"
              v-bind="$select"
              v-model="ct.tipo[2]"
              :label="ct.tipo[1]"
              :items="filtroPresupuestos"              
              item-value="id"
              item-text="label"
              @change="eventFiltro()">
            </v-select>
            
            <v-select 
              style="flex: 0 0 17%;padding-left:5px;"
              v-bind="$select"
              v-model="ct.cia_id[2]"
              :label="ct.cia_id[1]"
              :items="itemsCia"
              item-value="id"
              item-text="label"
              @change="eventFiltro()">
            </v-select>

            <v-btn  
              style="flex: 0 0 10%;margin-left:35px;"              
              v-bind="$cfe.btn.buscaTexto"              
              @click="eventFiltro()">
                Refrescar  
                <v-icon small style="padding-left:5px">{{ 'mdi-refresh' }}</v-icon>                
            </v-btn>
                                    
            <div v-show="masterEstado!='nuevo' && ct.ano[2]>= new Date().getFullYear()">              
              <funPresUtil
                style="flex: 0 0 20%"                  
                :dataPresUtil="dataPresUtil">                 
              </funPresUtil>
            </div>
          </div>

          <!-- Grid -->
          <baseGridMD            
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"           
            dense 
            @onEvent="execAccion">
 
              <!-- Slot botonera Top -->        
              <template v-slot:gridTop="{}">
                <div v-if="masterEstado== 'ver'" style="margin-top:10px">
                  <baseBtraMto class="conflex"
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>
                </div>
              </template>

              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">                
                <baseBtraMto                  
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>

              <!-- Slot campo Compañía -->
              <template v-slot:name_cia_id="{ item }">                
                <span>{{ item.name_cia_id.substring(0, 6) }}</span>
              </template>

              <!-- Slot campo Tipo Servicio -->
              <template v-slot:name_id_presciac="{ item }">                
                <span>{{ item.id_presciac }}</span>
              </template>

              <!-- Slot campo Preferente -->
              <template v-slot:preferente="{ item }">                
                <span style="color:green;font-weight: bold">{{ item.preferente== 0? "" : "P" }}</span>
              </template>

              <!-- Slot campo Diferencia -->
              <template v-slot:dif="{ item }">                
                <span>{{ getDif(item) }}</span>
              </template>
         
          </baseGridMD>
        </div>
      </v-sheet>
    </div>
    

    <!-- Mto Presupuestos -->
    <component
      :is="componenteDinamico"
      :syncDisparo="syncDisparoSend"
      :syncAccion="syncAccionSend"    
      syncUpdate      
      :storeRaiz="storeName"
      :masterStore="storeName"
      :recordAux='masterSyncRecord'
      :readOnly="readOnly"
      :viewMtoOnly="viewMtoOnly">
    </component>   
  </div>
</template>


<script>

  import { mixF } from "@/mixins/mixF.js";  
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import funesPresMD from "@/components/funesPresMD";  
  import funPresUtil from "@/components/funPresUtil";  
  
  export default {
    mixins: [mixF],
    components: { baseGridMD, baseBtraMto, funesPresMD, funPresUtil },
    props: {},

    data() {
      return {
        stIni: {
          api: "funesPresF",
          name:"funesPresF",
          titulo:"Presupuestos Funerarias Sucursales",          
          mView:'funesPresMD', // Módulo manteniniento asociado
          pView:[]             // Permisos del mantenimiento asociado
        },
       
        ST_tipo_presup: null,
        itemsAno: null,
        itemsCia: null,
        itemsDescripcion: null
      };
    },

    methods: {

      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)"); 

        this.headers = [{        
            name:[],
            fields:[        
              { text: "Ver",value: "acciones", sortable: false, slot:true, width:"5px"},
              { text: "AÑO", value: "ano", width:"5px" },
              { text: "CIA", value: "name_cia_id", slot:true, width:"5px" },
              { text: "TIPO", value: "name_id_presciac", width:"5px" },
              { text: "MUNICIPIO", value: "name_municipio", width:"25px" },
              { text: "PFT", value: "preferente", slot:true, width:"5px" },
              { text: "DESCRIPCIÓN", value: "descrip", width:"25px" },
              { text: "IMPORTE", value: "imp", width:"5px" },
              { text: "AÑO ANT.", value: "imp_ant", width:"5px" },
              { text: "DIF %", value: "dif", slot:true, width:"5px" },
              { text: "P.DIRECTO", value: "sup_pd", width:"5px" },
              { text: "IMP.CIA", value: "imp_cia", width:"5px" },
            
          ]}
        ];

        this.headerGrid= this.headerSet();

        // obtengo items tipo presupuestos
        this.ST_tipo_presup = this.$store.state.datos_iniciales.tipo_presup;
        this.filtroPresupuestos=this.$store.state.datos_iniciales.filtroPresupuestos;

        //
        this.itemsAno = [
          { id: "", ano: "", label: "TODOS" },
          { id: "1", ano: 2019, label: "2023" },
          { id: "2", ano: 2020, label: "2022" },
          { id: "3", ano: 2021, label: "2021" },
          { id: "4", ano: 2020, label: "2020" },
          //{ id: "5", ano: 2019, label: "2019" }
        ];

        this.itemsCia = [
          //{ id: "", label: "TODAS LAS CIAS" },
          { id: "83", label: "MAPFRE" },
          //{ id: "50165", label: "KUTXABANK" },
          ////{ id: "50508", label: "KUTXABANK" }
        ];      

        this.$set(this.ct.ano, 2, new Date().getFullYear());

        //
        this.autoLoad= "buscar";
      },
    

      //
      // pendiente: aplicar el mismo que en el mx
      // Devuelvo % de la diferencia entre importe e importe año anterior
      getDif(item) {        
        let imp_ant = Number(item.imp_ant);
        let imp = Number(item.imp);
        if (imp <= 0 || imp_ant <= 0) return "";
        return parseFloat(((imp - imp_ant) / imp_ant) * 100).toFixed(2);
      },

    },


    computed: {
      relationID() {
        return this.masterField;
      },


      dataPresUtil() {      
        if (!Object.keys(this.masterSyncRecord).length) return {};

        let obj={};
        obj.ano= this.ct.ano[2];
        obj.funeraria= this.masterSyncRecord;
        obj.presupuesto= this.filtroPresupuestos.find(x => x.id == this.ct.tipo[2]);
        obj.cia= this.itemsCia.find(x => x.id == this.ct.cia_id[2]);
        console.log("obj:",obj)
        return obj;
      }
    },

  };

</script>
