<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs">
          <v-btn v-bind="$cfe.btn.buscaTexto" v-on="on">
            OPERACIONES AUTOMATICAS
          </v-btn>
        </div>
        <!-- <v-btn color="primary" fab x-small dark v-bind="attrs" v-on="on">
            <v-icon>mdi-hammer-wrench</v-icon>
          </v-btn> -->
        <!-- </div> -->
      </template>

      <v-list dense>
        <!-- <v-subheader>OPERACIONES AUTOMCATICAS</v-subheader> -->
        <v-list-item
          v-for="(item, index) in A_datos"
          :key="index"
          @click="set_filtro(item)"
        >
          <v-list-item-icon>
            <v-icon> {{ item.icono }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ item.oper }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog content-class="modal" persintent v-model="modal">
      <template>
        <div class="contenedor centrado">
          <v-sheet :elevation="4" style="width:500px">
            <baseHeader
              :cfg="$cfe.headers.finder"
              titulo='OPERACIONES AUTOMÁTICAS'
              @onEvent="modal=false"> 
            </baseHeader>
            
            <div class="columna contenedor">
              <div>
                <h2 style="color:red;text-align:center">ATENCIÓN</h2>
                <h3 style="text-align:center;font-weight:bold">
                  Esta operación se aplica a TODOS los presupuestos
                  seleccionados y NO podrá deshacerse
                </h3>

                <vllabel
                  label="AÑO"
                  :txt="dataPresUtil.ano"
                  stilol="flex:1 1 25%"
                  stilot="flex:1 1 75%"
                >
                </vllabel>
                <vllabel
                  label="FUNERARIA:"
                  :txt="dataPresUtil.funeraria.name"
                  stilol="flex:1 1 25%"
                  stilot="flex:1 1 75%"
                >
                </vllabel>
                <vllabel
                  label="COMPAÑIA:"
                  :txt="dataPresUtil.cia.label"
                  stilol="flex:1 1 25%"
                  stilot="flex:1 1 75%"
                >
                </vllabel>
                <vllabel
                  label="PRESUPUESTOS:"
                  :txt="dataPresUtil.presupuesto.label"
                  stilol="right;flex:1 1 25%"
                  stilot="flex:1 1 75%"
                >
                </vllabel>
                <v-divider style="margin: 10px"></v-divider>
                <vllabel
                  label="OPERACION"
                  :txt="filtro.oper"
                  stilol="flex:1 1 25%"
                  stilot="flex:1 1 75%;font-weight:bold;color:red"
                >
                </vllabel>

                <div v-if="filtro.api == 1">
                  <v-text-field
                    v-bind="$input"
                    v-model="F.contratador"
                    label="Contratador"
                  >
                  </v-text-field>
                  <compfecha
                    style="width:160px"
                    :schema="F.fecha"
                    :edicion="true"
                  ></compfecha>
                </div>

                <div v-if="filtro.api == 2">
                  <div class="centrado" style="width:150px">
                    <v-input_n
                      style="width:150px"
                      v-bind="$input"
                      v-model="F.porcentaje"
                      simbolo="%"
                      label="Porcentaje"
                    >
                    </v-input_n>
                  </div>

                  <div class="centrado">
                    <v-btn
                      v-bind="$cfg.btn.busca_with_text"
                      @click="ejecutar(0)"
                    >
                      Aplicar porcentaje
                    </v-btn>

                    <v-btn
                      v-bind="$cfg.btn.busca_with_text"
                      @click="ejecutar(1)"
                    >
                      Revertir porcentaje
                    </v-btn>
                  </div>
                </div>
                <!-- producto select -->
                <div 
                  v-if="filtro.api == 3 || filtro.api == 4 || filtro.api == 5"
                 
                >
                  <v-select
                    v-bind="$input"
                    v-model="F.p.producto"
                    :items="productos"
                    item-text="name"
                    item-value="id"
                    return-object
                    label="Seleccione Producto"
                    value="productos.id"
                  >
                  </v-select>
                </div>

                <div v-if="filtro.api == 3">
                  
                  <v-input_n
                    style="width:45%"
                    v-bind="$input"
                    v-model="F.p.precio"
                    label="Nuevo Precio"
                  >
                  </v-input_n>
                
                  
                    <v-select
                    style="width:45%"
                      v-bind="$input"
                      v-model="A_datos[2].suplido"
                      :items="A_datos[2].cambioSuplido"
                      item-text="name"
                      item-value="id"
                     
                      label="Suplido"
                      
                    >
                    </v-select>
                    <v-select
                     style="width:45%"
                      v-bind="$input"
                      v-model="A_datos[2].iva"
                      :items="A_datos[2].cambioIva"
                      item-text="name"
                      item-value="id"
                      
                      label="IVA"
                      
                    >
                    </v-select>
                  
                </div>

                <div v-if="filtro.api == 4">
                  <!-- <v-text-field
                    color="stealblue"
                    v-bind="$input"
                    v-model="F.p.namea"
                    label="Descripcion ampliada opcional"
                  >
                  </v-text-field> -->

                  <div class="conflex" style="align-items:center;">
                    <v-checkbox
                      style="flex:1 1 10%;margin-left:0px"
                      v-bind="$input"
                      v-model="F.p.sp"
                      color="red"
                      label="Pago E"
                      true-value="1"
                      false-value="0"
                      dense
                    >
                    </v-checkbox>

                    <v-checkbox
                      style="flex:1 1 10%;margin-left:0px:margin-right:5px"
                      v-bind="$input"
                      v-model="F.p.sup"
                      label="Suplido"
                      color="green"
                      true-value="S"
                      false-value="N"
                    >
                    </v-checkbox>

                    <v-select
                      style="flex:1 1 15%;margin-left:5px"
                      v-bind="$input"
                      v-model="F.p.iva"
                      :items="iva"
                      label="IVA %"
                    >
                    </v-select>
                    <!-- 
                   <v-input_n
                    style="flex:1 1 20%"
                    v-bind="$input"
                    v-model="p.und"
                    label="Und"
                    simbolo=''
                    @blur="recalc_imp()"
                  >
                  </v-input_n> -->

                    <v-input_n
                      style="flex:1 1 20%;margin-left:20px"
                      v-bind="$input"
                      v-model="F.p.precio"
                      label="Precio"
                    >
                    </v-input_n>

                    <!-- <vtlabel
                    style="flex:1 1 20%"
                    stilot="text-align:right"
                    v-bind="$input"
                    :txt="p.imp | num(2)"
                    label="Importe"
                  >
                  </vtlabel> -->
                  </div>
                </div>

                <div v-if="filtro.api != 2" class="conflex centrado"   style="margin-top:35px">
                  <v-btn v-bind="$cfg.btn.busca_with_text" @click="ejecutar()">
                    Ejecutar operación
                  </v-btn>
                </div>
              </div>
              <!-- <div>cia:{{ dataPresUtil.cia.id }}</div>
            <div>cia:{{ dataPresUtil.cia.label }}</div>
            <div>descrip:{{ dataPresUtil.presupuesto.id }}</div>
            <div>descrip:{{ dataPresUtil.presupuesto.label }}</div>
            <div>filtro:{{ filtro.oper }}</div>
            <div>mporte:{{ p.imp }}</div> -->
            </div>
          </v-sheet>
        </div>
      </template>
    </v-dialog>
  </div>
</template>



<script>

  import baseHeader from "@/base/baseHeader";
  import plugs from "@/common/general_plugs";
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
  
  export default {

    components: { compfecha, baseHeader },
    props: {     
      dataPresUtil: {}
    },
        
    data() {
      return {
        modal: false,    
        filtro: {},
        A_datos: [
          {
            oper: "Cambia fecha de negociacion",
            tcc: "*NEGOCIAC:",
            fun: "NEGOCIACION",
            icono: " mdi-calendar-account",
            api: 1
          },
          {
            oper: "Aplicar subida general",
            tcc: ["*AUMENTO:", "*REVERTIR:"],
            aviso:["PORCENTAJE", "REVERTIR PORCENTAJE"],
            fun: ["PORCENTAJE", "PORC_INVERSO"],
            icono: "mdi-upload-multiple",
            api: 2
          },
          {
            oper: "Cambia precio de producto",
            tcc: "*PRECIO:",
            fun: "PRECIO",
            icono: "mdi-currency-eur",
            cambioSuplido:[{name:"Mantener (No cambiar)", id:99},
                          {name: "SI",id:"S"},
                          {name: "NO",id:"N"}],
            cambioIva:[{name:"Mantener (No cambiar)", id:99},
                          {name: "21%",id:21},
                          {name: "10%",id:10},
                          {name: "3%",id:3},
                          {name: "4%",id:4},
                          {name: "0%",id:0}
                          ],
            iva:99,
            suplido:99,
            api: 3
          },

          {
            oper: "Añadir producto",
            tcc: "*AÑADIR:",
            fun: "ADD",
            icono: "mdi-cart-plus",
            api: 4
          },
          {
            oper: "Eliminar producto",
            tcc: "*ELIMINAR:",
            fun: "DELETE",
            icono: "mdi-cart-minus",
            api: 5
          }
        ],

        // pendiente: ver si hay que excluir algun producto
        productos: [...this.$store.state.datos_iniciales.productos],
        iva: [21, 10, 3, 0, 4, 6.5],
        f: {
          p: {
            producto: 0,
            namea: "",
            sp: 0,
            sup: 0,
            iva: 21,
            und: 1,
            precio: 0,
            imp: 0
          },
          contratador: "",
          fecha: {
            f: "",
            label: "Fecha",
            value: "",
            comp: { type: "fecha", format: "fh" }
          },
          porcentaje: 0
        },
        F: {},


        }
    },


    methods: {
      
      /* event_capture(accion) {
        if (accion === "cerrar_form") this.dialog = false;
      }, */
      
      eventHeader(evt) {
        //if (accion === "cerrar_form") this.dialog = false;
        console.log('eventHeader: ', evt);
      },
      
      set_filtro(item) {
        this.modal = true;
        this.filtro = item;
        // Inicializa valores formulario
        this.F = JSON.parse(JSON.stringify(this.f));
        this.A_datos[2].iva=99
        this.A_datos[2].suplido=99
      },

      ejecutar(i=99) {
        let avisoPorcentaje=''
        if (i < 99) avisoPorcentaje=this.filtro.aviso[i];

        this.$root.$alert
          .open(avisoPorcentaje+"Seguro que desea ejecutar la operación?", "confirm")
          .then(r => {
            if (r) this.ejecutar_si(i);
        });
      },

      async ejecutar_si(i){
        // solo recibe arg de revertir o aumentar
        let operacion=i==99?this.filtro.fun:this.filtro.fun[i];
        let cias=this.dataPresUtil.cia.id==0?'83$50165':this.dataPresUtil.cia.id;
        let tcc=this.tccId()+';90;';
        let presupuestos=this.dataPresUtil.presupuesto.id==''?99:this.dataPresUtil.presupuesto.id;
        presupuestos=String(presupuestos).split('$').join('$');
        
        let param1 = 0;
        let param2 = 0;
        let param3 = 0;
        let param4 = 0;
        let param5 = 0;
        let param6 = 0;
        console.log(operacion,this.dataPresUtil.funeraria.id,this.dataPresUtil.ano,cias,tcc,param1,param2,param3,param4,param5,param6)
        // solo manda arg revertir o aumentar
        switch (this.filtro.api) {
          case 1:
            //NegociaciC3n
  
            param1 = this.F.contratador;
            param2 = this.F.fecha.value.split('-').join("");
            param4 = presupuestos;
            tcc+=this.filtro.tcc + this.F.fecha.value;
            //
            if (this.F.contratador=='' || this.F.fecha.value=='') {
                this.$root.$alert.open("Faltan datos", "error");
                return
            }
            break;

          case 2:
            //PORCENTAJE
            
            param1=Number(this.F.porcentaje);
            if (param1 > 8 || param1< -8 || param1 == 0 || isNaN(param1)){
              this.$root.$alert.open("Revise porcentaje (fuera de rango 5%)", "error");
              return
            }
            param1=1+Number(this.F.porcentaje)/100;
            tcc+=this.filtro.tcc[i] +" "+this.F.porcentaje+"%";
          //
  
            break;

          case 3:
            //precio

            if (this.F.p.producto=='0' ) {
                this.$root.$alert.open("Debe introducir producto/servicio", "error");
                return
            }
            if ( this.F.p.precio==0 || isNaN(this.F.p.precio)) {
                this.$root.$alert.open("Debe introducir precio ", "error");
                return
            }
            //
            if (this.A_datos[2].iva!=0 && this.A_datos[2].suplido=="S"){
              this.$root.$alert.open("Si cambia suplido a SI, debe cambiar IVA a 0", "error");
              return
            }
            if (this.A_datos[2].iva!=99 && this.A_datos[2].suplido==99){
              this.$root.$alert.open("Si cambia IVA debe de cambiar suplido a NO", "error");
              return
            }
            if (this.A_datos[2].iva==99 && this.A_datos[2].suplido=='N'){
              this.$root.$alert.open("Si cambia suplido a NO, debe introducir IVA", "error");
              return
            }
            param1=this.F.p.producto.id;
            param2=Number(this.F.p.precio);
            param3=this.A_datos[2].iva;
            param4=presupuestos;
            param5=this.A_datos[2].suplido;
            param6=99;
            tcc+=this.filtro.tcc+" "+this.F.p.producto.name.substring(0,15)+" "+param2+"€"	
            tcc+=param3!=99?" iva: "+param3:''
            tcc+=param5!=99?" sup: "+param5:''
            
            if (param2>6000 || this.F.p.precio<-2000) {
                this.$root.$alert.open("Revise precio", "error");
                return
            }

            break;

          case 4:
          //add producto

          if (this.F.p.producto=='0' ) {
                this.$root.$alert.open("Debe introducir producto/servicio", "error");
                return
            }
            if (this.F.p.precio==0 || isNaN(this.F.p.precio)) {
                this.$root.$alert.open("Debe introducir precio", "error");
                return
            }
            //
            param1=this.F.p.producto.id;
            param2=Number(this.F.p.precio);
            param3=this.F.p.iva;
            param4=presupuestos;
            //param5=this.F.p.sup;
            param5=this.F.p.sup;
            if (param5=='0') param5='N';
            // solo puede ser 0 ,pongo 1 por si se cambiara
            if (param5=='1') param5='S';

            if (param5!='S' && param5!='N') {
                this.$root.$alert.open("suplido incorrecto", "error");
                return
            }
          
            console.log('datos sup:',this.F.p.sup);
            param6=this.F.p.sp;
            tcc+=this.filtro.tcc+" "+this.F.p.producto.name.substring(0,15)+" "+param2+"€ IVA:"+param3+" sup:"+param5+" PD:"+ (this.F.p.sp?"S":"N");	

            if (param3>0 && param5=="S") {
                this.$root.$alert.open("Revise iva/suplido", "error");
                return
            }

            break;

          case 5:
            //delete producto
            if (this.F.p.producto=='0') {
                this.$root.$alert.open("Debe introducir producto/servicio", "error");
                return
            }
            param1=this.F.p.producto.id;
            param4=presupuestos;
            tcc+=this.filtro.tcc+" "+this.F.p.producto.name.substring(0,15);

            break;
          default:
        }



        //sql_CALL(_operacion, _funID, _ano, _A_cias, _tcc, _param1, _param2, _param3, _param4, _param5, _param6) {
        let args={ 
            tipo:"fnc",
            accion: "mysqlProc",
            fn_args:[ {
                  call:"funpres_utiles_new",
                  args:{
                    OPERACION:operacion,
                    FUN_ID:this.dataPresUtil.funeraria.id,
                    ANO:this.dataPresUtil.ano,
                    A_CIAS:cias,
                    TCC:tcc,
                    PARAM1:param1,
                    PARAM2:param2,
                    PARAM3:param3,
                    PARAM4:param4,
                    PARAM5:param5,
                    PARAM6:param6
                    }
                }]
        };
        console.log("datos:",operacion,this.dataPresUtil.funeraria.id,this.dataPresUtil.ano,cias,tcc,param1,param2,param3,param4,param5,param6);
        console.log(tcc);
        console.log("api:", args, args.fn_args);
        //   };

        var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        
        console.log("resultado api preferente:", apiResult);

        this.$root.$alert.open("Operación realizada", "info");
    
            

        // Chequeo resultado
            // if (apiResult.r[0][0] != 0) {
        //   this.$root.$alert.open(
        //     " La funeraria ya tiene presupuesto y para este tipo solo se permite uno",
        //     "error"
        //   );
        //   return false;
        // }
    
      },

      tccId(){
        let fh = new Date();
        let tcc=`${fh.getFullYear()}${String(fh.getMonth() + 1).padStart(2,"0")}${String(fh.getDate()).padStart(2, "0")}`;
        tcc+=`${String(fh.getHours()).padStart(2,"0")}${String(fh.getMinutes()).padStart(2,"0")};`;
        tcc+= this.$store.state.G.USUARIO.substring(0,10);
        return tcc
      }
    }
  };
</script>

