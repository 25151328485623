<!-- Mto expansible Presupuestos Funerarias -->

<template>
  <div class="funesPresMX" v-if="loaded">
    <v-sheet class="contenedor"> 
     
        <!-- Botones Mto -->
        <div class="pb-2 d-flex justify-left" v-if="!masterNoEdit">
          <baseBtraMto class="conflex" style="width:230px;"           
            :modulo="btMtoCfg" 
            :estado="estado"
            @onEvent="execAccion">        
          </baseBtraMto>        
        </div>

        <!-- Controles del Mto -->            
        <div style="display:flex">          
          <uiText
            style="width:50%"                        
            v-model="ct.name_prod_id[2]"
            :label="ct.name_prod_id[1]"
            readonly>                          
          </uiText>

          <uiText
            style="width:50%"                        
            v-model="ct.namea[2]"
            :label="ct.namea[1]"
            :disabled="noEdit">
          </uiText> 
        </div>

        <div class="conflex">
          <div class="conflex" style="padding: 0 10px; align-items:center;justify-content: space-between;flex:30%">
          
            <v-checkbox
              style="margin-left:5px"
              v-bind="$input"
              v-model="ct.sp[2]"
              color="red"
              label="Pago externo"
              true-value="1"
              false-value="0"
              :disabled="noEdit"
              dense>            
            </v-checkbox>

            <v-checkbox
              v-bind="$input"
              v-model="ct.grup[2]"
              label="Traslado"
              true-value="T"
              false-value="P"
              :disabled="noEdit">              
            </v-checkbox>
            
            <v-checkbox
              v-bind="$input"
              v-model="ct.sup[2]"
              label="Suplido"
              color="green"
              true-value="S"
              false-value="N"
              :disabled="noEdit"
              @change="changeSuplido">            
            </v-checkbox>

          </div>

          <uiText
            style="width:70px"                        
            v-model="ct.und[2]"
            :label="ct.und[1]"
            :disabled="noEdit"
            @blur="calculoImporte">
          </uiText>         

          <v-select  style="flex: 0 0 80px"
            v-model="ct.iva[2]"
            v-bind="$select"
            label="IVA"
            :items="getIvas"
            :disabled="noEdit || ct.sup[2]==='S'"
            item-value="value">
          </v-select> 

          <uiText
            style="width:100px"                        
            v-model="ct.pvp[2]"
            :label="ct.pvp[1]"
            :disabled="noEdit"
            format="money"
            @blur="calculoImporte">
          </uiText>

          <uiText
            style="width:100px"                        
            v-model="ct.imp[2]"
            :label="ct.imp[1]"
            format="money"
            readonly>
          </uiText>         
        </div>      
    </v-sheet>
  </div>
</template>



<script>

  import { mixMX } from "@/mixins/mixMX.js";
  import baseBtraMto from "@/base/baseBtraMto";
  
  export default {    
    mixins: [mixMX],
    components: { baseBtraMto },
    props: {
      selectProduct: { type:Object, default:null },
      rt: { type:String, default:'0' },
      ivaGeneral: { type:String, default:'0' },
    },

    methods: {

      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");
        this.line2End= true;       
      },


      // gancho final para todas las operaciones de CRUD
      crudFin() {
        if (this.ct.iva[2]) return;
        this.$set(this.ct.iva, 2, this.ivaGeneral);     
      },
           

      // recalculo importe linea detalle
      calculoImporte() {
        let r= this.ct.und[2] * this.ct.pvp[2];
        this.$set(this.ct.imp, 2, Math.round(Number((r * 100).toFixed(2))) / 100);
      },

      
      // validación de datos antes de guardar
      validateParticular() {
        // get iva general    
        let rt_ivas= this.$store.state.datos_iniciales.rTribut[this.rt].tipos.map(i=>Number(i.value));        
        let iva = Number(this.ct.iva[2]);


        // caso presupuesto/factura de SUPLIDO chequeo iva y sup        
        if (this.masterSyncRecord.sup=="1" && this.ct.sup[2]!="S"){
          this.$root.$alert.open('Este tipo de presupuesto/factura SUPLIDO solo admite Suplidos', 'error' )
          return false;
        }

        // comprueba si es suplido y ha metido iva        
        if(this.ct.sup[2]==="S" && iva!=0) {
            this.$root.$alert.open('IVA/SUPLIDO incompatible', 'error' )
            return false;
        }

        // comprueba si no ha dejado el producto en blanco 
        if (this.ct.name_prod_id[2] === "") {
          this.$root.$alert.open("Debe introducir producto/servicio", "error");
          return false;
        }    

        // comprueba si es un producto con un iva al 10%     
        if (this.$store.state.datos_iniciales.iva10.includes(Number(this.ct.prod_id[2])) && Number(this.ct.iva[2])> 10) {
          this.$root.$alert.open('Tipo de producto Flores con IVA incorrecto', 'error' )
          return false;
        }

        // comprueba si no ha introducido unidades 
        if (Number(this.ct.und[2]==0 || Number(this.ct.und[2]>5000 || isNaN(this.ct.und[2])))) {
          this.$root.$alert.open("Error en unidades de producto", "error");
          return false;
        }
        
        // comprueba si ha introducido precio
        if (isNaN(this.ct.pvp[2])) {
          this.$root.$alert.open("Error en precio de producto", "error");
          return false;
        }
                
        // ver si hay productos duplicados
        let records= this.records;
        if (records.find(element => (element.prod_id==this.ct.prod_id[2] && element.namea==this.ct.namea[2] && element.id!=this.ct.id[2]))) {
          this.$root.$alert.open("Producto/servicio duplicado","error");
          return false;
        }
        
        if (rt_ivas.includes(iva)) return true;

        this.$root.$alert.open(
          `Revise: Tipo de Impuesto ${this.$store.state.datos_iniciales.rTribut[this.rt].name} producto ${iva}%`, "error");
          return false;
      },


      // entra al cambiar el checkbox de suplidos
      // true. Iva a 0.0
      // false. Iva general
      changeSuplido(val) {      
        if (val== 'S') {        
          this.ct.iva[2]= "0.0";
          return;
        }

        // asigno IVA general
        this.ct.iva[2]= this.ivaGeneral;
      },


      // entro al seleccionar un producto 
      // actualizo nombre e id del producto en controles del MX
      changeSelectProducto() {
        console.log('changeSelectProducto;; ', this.selectProduct);
       
        if (this.selectProduct== null || typeof this.selectProduct=== 'undefined') return;
        let item= this.selectProduct;

        // Paso nombre de producto a control del xm añadiendole la clave al literal
        this.$set(this.ct.prod_id, 2, item.id);       
        this.$set(this.ct.name_prod_id, 2, ("(" + item.clave + ") " + item.name));
                     
        // Paso al record del mx la clave porque no tiene ctrl
        //this.$store.state[this.storeName].record.clave =   item.clave;
      },


      
    },


    computed: {

      getIvas() {       
        if (this.rt!= '') {
          return this.$store.state.datos_iniciales.rTribut[this.rt].tipos;
        }

        return[];         
      },

    },


    watch: {
      selectProduct: {        
        handler() {          
          this.changeSelectProducto();
        }
      }
    }


  };
</script>
